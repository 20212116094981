import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Head from 'components/head';
import Layout from 'components/layout';

import Section from '~v2/section';
import Wrapper from '~v2/elements/container/wrapper';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';
import Copy from '~v2/elements/copy';
import { MarkdownContent, MarkdownContainer } from 'blog.css.js';
import { colors } from 'constants/theme';

const PrivacyPolicy = ({
  data: {
    privacypolicyJson: { title, hero_section, copy },
  },
}) => (
  <Layout isSmallHeader={true}>
    <Head
      pageTitle={title}
      pageDescription="Learn more about Trace's Privay Policy here."
    />
    <Wrapper>
      <TitleContainer>
        <Title as="h1">{hero_section.title}</Title>
        <Copy>{hero_section.tagline}</Copy>
      </TitleContainer>
      <Section
        boundsProps={{
          fluid: true,
          DESKTOP_DOWN: { padding: '0 2rem' },
          DESKTOP: { width: '100%', padding: '0 2rem' },
        }}
        left={{
          boxProps: {
            fluid: true,
            TABLET_DOWN: { width: '0%' },
            TABLET: { width: '5%' },
            DESKTOP_UP: { width: '15%' },
          },
          backgroundProps: {
            bgColor: colors.warm_grey,
          },
        }}
        right={{
          children: (
            <MarkdownContainer>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: copy.childMarkdownRemark.html,
                }}
              />
            </MarkdownContainer>
          ),
          boxProps: {
            fluid: true,
            shiftUp: true,
            PHONE: { width: '100%', padding: '0' },
            TABLET_DOWN: { width: '100%', padding: '0 2rem' },
            TABLET: { width: '95%' },
            DESKTOP_UP: { width: '85%' },
          },
        }}
      />
    </Wrapper>
  </Layout>
);

PrivacyPolicy.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrivacyPolicy;

export const query = graphql`
  query PrivacyPolicyQuery {
    privacypolicyJson {
      title
      hero_section {
        title
        tagline
      }
      copy {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
