import styled from 'styled-components';
// import { BREAKPOINTS } from 'constants/breakpoints';
import MEDIA from 'helpers/mediaTemplates';
import { palette, colors } from 'constants/theme';

export const MarkdownContainer = styled.div`
  margin-left: 0rem;

  ${MEDIA.MIN_DESKTOP`
    margin-left: 3rem;
  `};
`;

export const Pagination = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 6rem 0;

  a {
    text-decoration: none;
  }

  ${MEDIA.MIN_DESKTOP`
    margin-left: 3rem;
  `};
`;

export const MarkdownContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  h1 {
    margin-top: 2.5rem;
    margin-bottom: 2.25rem;
    color: ${colors.trace_black};
    font-weight: 700;
    font-size: 3.75rem;
    font-family: 'Archivo Black';
  }

  h2 {
    margin-top: 3.75rem;
    margin-bottom: 1.25rem;
    color: ${colors.cool_grey};
    font-weight: 300;
    font-size: 3rem;
    font-family: 'Archivo Black';
  }

  h3 {
    margin-top: 2.25rem;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 2rem;
    font-family: 'Domaine Display';
  }

  h4 {
    margin-top: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 1.5rem;
    font-family: 'Archivo Black';
  }

  h5 {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    font-weight: 700;
    font-size: 1.25rem;
    font-family: 'Archivo Black';
  }

  h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    color: ${colors.dusty_gold};
    font-size: 1rem;
    font-family: 'Archivo Black';
  }

  p {
    margin-top: 0;
    margin-bottom: 1.25rem;
    font-size: 1.2rem;
    line-height: 1.85rem;
  }

  a {
    background-size: 0.06em 0.06em;
    background-repeat: repeat-x;
    background-position-y: 93%;
    background-position-x: 0;
    font-weight: bold;
    color: ${colors.dusty_gold};
    text-decoration: none;
    transition: 0.15s;
  }

  a:active {
    background-color: #eee;
    color: #555;
    text-decoration: none;
  }

  a:hover {
    color: ${colors.cool_grey};
  }

  b,
  strong {
    font-weight: bolder;
    color: ${colors.darkGreyBlue};
  }

  em,
  i {
    font-style: italic;
  }

  del,
  s,
  strike {
    text-decoration: none;
    background-size: 0.06em 0.06em;
    background-repeat: repeat-x;
    background-position-y: 65%;
    background-position-x: 0;
  }

  code,
  pre,
  samp,
  var {
    font-size: 95%;
    font-family: monospace;
    font-weight: 400;
    color: ${palette.greyBlue};
  }

  pre {
    display: block;
    margin: 1rem 0;
    padding: 2rem;
    background-color: #eee;
    border-left: 0.1rem solid #888;
    border-radius: 0;
  }

  p code {
    padding: 0 0.2em 0.1em;
    background-color: #eee;
  }

  p var {
    padding: 0 0.2em 0.1em;
    background-color: #eee;
    font-weight: bolder;
    font-style: normal;
  }

  p samp {
    padding: 0 0.2em 0.1em;
    margin: 0 0.1em;
    background-color: #666;
    color: #eee;
  }

  kbd {
    position: relative;
    padding: 0.2em 0.2em 0.1em 0.2em;
    margin: 0 0.1em;
    bottom: 0.125em;
    font-family: Montserrat, sans-serif;
    font-size: 0.8em;
    background-color: #eee;
    border-bottom: 0.05em solid #aaa;
  }

  blockquote {
    margin: 2rem -1.5rem;
    padding: 2rem 4rem 2rem 4rem;
    ${'' /* background: linear-gradient(to left, #fefefe, #fafafa); */}
    font-family: 'Domaine Display', serif;
    color: #666;
    font-size: 2rem;
    font-style: italic;
    line-height: 1.5;
  }

  blockquote p {
    display: inline;
    font-size: 1.8rem;
    line-height: 2.8rem;
    font-family: 'Domaine Display', serif;
  }

  blockquote *:first-child {
    margin-top: 0;
  }

  blockquote::before {
    content: '“';
    display: block;
    position: relative;
    height: 0;
    left: -3rem;
    top: -1rem;
    font-size: 3.5rem;
    color: ${colors.spanish_white};
  }

  abbr[title] {
    text-decoration: none;
    background-size: 0.1em 0.06em;
    background-repeat: repeat-x;
    background-position-y: 100%;
    background-position-x: 0;
    font-feature-settings: 'smcp';
  }

  ul {
    list-style: none;
    margin-left: -0.5rem;
    margin-bottom: 1.25rem;
    padding-left: 2em;
    font-size: 1.2rem;
    line-height: 1.55rem;
  }

  ul > li {
    padding-left: 0;
    margin-bottom: 8px;
  }

  ul > li::before {
    content: '»';
    display: block;
    position: relative;
    height: 0;
    width: 2em;
    margin-left: -2.5em;
    font-weight: bolder;
    color: #888;
    text-align: right;
  }

  ul ul > li::before {
    content: '›';
    display: block;
    position: relative;
    height: 0;
    width: 2em;
    margin-left: -2.5em;
    font-weight: bolder;
    color: #888;
    text-align: right;
  }

  ol {
    list-style: none;
    margin-left: -0.5rem;
    margin-bottom: 1.25rem;
    padding-left: 2em;
  }

  ol > li {
    padding-left: 0;
    margin-bottom: 8px;
    counter-increment: ol-count;
  }

  ol > li:first-child {
    counter-reset: ol-count;
  }

  ol > li::before {
    content: counter(ol-count) '.';
    display: block;
    position: relative;
    height: 0;
    width: 2em;
    margin-left: -2.5em;
    font-weight: bolder;
    color: #888;
    text-align: right;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin: 0.5em 0;
  }

  img {
    display: block;
    width: calc(100% + 2rem);
    margin: 1rem -1rem;
  }

  figure {
    display: inline-table;
    margin: 0rem -2rem 1rem -2rem;
    padding: 2rem 2rem;
    background-color: #fafafa;
  }

  figure img {
    display: inline-block;
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }

  figure img + img {
    margin-left: 1rem;
  }

  figure figcaption {
    max-width: 100%;
    margin: 1rem auto -1rem auto;
    font-size: 0.9rem;
    font-style: italic;
  }

  .iframe-container {
    margin-bottom: 2rem;

    iframe {
      width: 100%;
      height: auto;
      min-height: 280px;

      ${MEDIA.MIN_TABLET`
        min-height: 320px;
      `};

      ${MEDIA.MIN_DESKTOP`
        min-height: 480px;
      `};

      ${MEDIA.MIN_LG_DESKTOP`
        min-height: 590px;
      `};
    }
  }

  table {
    margin: 0 -1rem 1rem -1rem;
    width: calc(100% + 2rem);
    overflow: hidden;
    border-collapse: collapse;
  }

  table caption {
    padding-top: 0.5rem;
    border-top: 1px solid #eee;
    caption-side: bottom;
    text-align: center;
    font-style: italic;
    color: #666;
  }

  tr {
    transition: 0.15s;
  }

  thead tr {
    border-bottom: 2px solid #888;
  }

  tfoot tr {
    border-bottom: none;
    border-top: 1px solid #eee;
    font-weight: bolder;
  }

  tbody tr:hover,
  tfoot tr:hover {
    background-color: #eee;
  }

  td,
  th {
    position: relative;
    padding: 0.3rem 1rem;
  }

  th {
    font-weight: bolder;
  }

  tbody tr:first-of-type td,
  tbody tr:first-of-type th {
    padding-top: 0.5rem;
  }

  hr {
    height: 0;
    width: 10rem;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    margin: 2rem auto;
  }
`;
